import { provideNativeDateAdapter } from '@angular/material/core'
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'

import { AppConfig } from '~core/config/config'

export const provideMaterial = () => [
  provideNativeDateAdapter(),
  { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: AppConfig.SNACKBAR },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: AppConfig.FORM_FIELD },
  { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: AppConfig.DIALOG },
]
