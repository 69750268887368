import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'
import {
  TRANSLOCO_MISSING_HANDLER,
  Translation,
  TranslocoLoader,
  TranslocoMissingHandler,
  provideTransloco,
} from '@jsverse/transloco'
import { provideTranslocoLocale } from '@jsverse/transloco-locale'
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat'

import { commonI18nConfig } from '~common/common.config'
import { AppConfig } from '~core/config/config'
import logger from '~core/utils/logger'
import { competitorsI18nConfig } from '~features/competitors/competitors.config'
import { fbAdAccountI18nConfig } from '~features/facebook-ad-account/facebook-ad-account.config'
import { fbPageI18nConfig } from '~features/facebook-page/facebook-page.config'
import { igAccountI18nConfig } from '~features/instagram-account/instagram-account.config'
import { integrationsI18nConfig } from '~features/integrations/integrations.config'
import { inOrganizationI18nConfig } from '~features/linkedin-organization/linkedin-organization.config'
import { projectsI18nConfig } from '~features/projects/projects.config'
import { reportsI18nConfig } from '~features/reports/reports.config'
import { userI18nConfig } from '~features/user/user.config'

export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient)

  getTranslation(lang: string) {
    // TODO: ensure that lang is loaded before load components (also for scoped translations)
    logger.debug('getTranslation', lang)
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`)
  }
}

export class MissingHandler implements TranslocoMissingHandler {
  handle() {
    return ''
  }
}

export const translocoProviders = [
  provideTransloco({
    config: AppConfig.TRANSLOCO,
    loader: TranslocoHttpLoader,
  }),
  provideTranslocoLocale(AppConfig.TRANSLOCO_LOCALE),
  provideTranslocoMessageformat(AppConfig.TRANSLOCO_MESSAGE_FORMAT),
  [{ provide: TRANSLOCO_MISSING_HANDLER, useClass: MissingHandler }],
]

export const translationsProviders = [
  ...commonI18nConfig,
  ...userI18nConfig,
  ...integrationsI18nConfig,
  ...projectsI18nConfig,
  ...reportsI18nConfig,
  ...inOrganizationI18nConfig,
  ...igAccountI18nConfig,
  ...fbPageI18nConfig,
  ...fbAdAccountI18nConfig,
  ...competitorsI18nConfig,
]
