import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core'
import { Router } from '@angular/router'

import { NotificationService } from '~core/services/notification.service'
import { RollbarService } from '~core/services/rollbar.service'
import logger from '~core/utils/logger'
import { environment } from '~env'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error) {
    const notificationService = this.injector.get(NotificationService)

    if (this.isHttpErrorResponse(error)) {
      error = this.parseHttpErrorResponse(error)

      if (error) {
        // Server error
        if (error.status === 503) {
          this.injector.get(NgZone).run(() => this.injector.get(Router).navigateByUrl('/maintenance'))
        }
        notificationService.serverError(error)
      }
    } else {
      // Client Error
      notificationService.clientError(error)
    }

    // Log errors
    switch (environment.stage) {
      case 'local':
        logger.error(error)
        break
      case 'staging':
        logger.error(error)
        if (!(error instanceof HttpErrorResponse)) {
          this.injector.get(RollbarService).error(error)
        }
        break
      case 'prod':
        if (!(error instanceof HttpErrorResponse)) {
          this.injector.get(RollbarService).error(error)
        }
        break
    }
  }

  private isHttpErrorResponse(error: Error) {
    return error instanceof HttpErrorResponse || error.message.includes('HttpErrorResponse:')
  }

  private parseHttpErrorResponse(error) {
    // work around to handle when a resolver throws badly formatted HttpErrorResponse
    if (!(error instanceof HttpErrorResponse)) {
      const errorResponse = JSON.parse(error.message.match(/{.*}/gm)[0] ?? null)
      return errorResponse ? new HttpErrorResponse(errorResponse) : null
    }

    return error
  }
}
