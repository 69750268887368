import { Component } from '@angular/core'
import { TranslocoModule } from '@jsverse/transloco'

@Component({
  selector: 'sb-maintenance',
  template: `<div class="mw-100 mt-5 text-center">
    <img src="../../../assets/img/logo.png" alt="Sbam" style="max-width: 200px" class="d-block mx-auto" />
    <p
      class="align-text-top mt-5 text-center"
      style="font-size: 1.8rem"
      [innerHTML]="'maintenance.text' | transloco"
    ></p>
    <!--  <button class="btn btn-primary" (click)="onRefresh()">{{ 'maintenance.button' | transloco }}</button>-->
  </div> `,
  standalone: true,
  imports: [TranslocoModule],
})
export class MaintenanceComponent {}
