import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { switchMap } from 'rxjs/operators'

import * as IntegrationActions from '../actions'

@Injectable()
export class IntegrationEffects {
  loadIntegrations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.loadIntegrations),
      switchMap(() => [
        { type: IntegrationActions.loadFacebookAccounts.type },
        { type: IntegrationActions.loadLinkedinAccounts.type },
      ]),
    )
  })

  constructor(private actions$: Actions) {}
}
