import { ChangeDetectionStrategy, Component, booleanAttribute, computed, inject, input, signal } from '@angular/core'
import { TranslocoModule } from '@jsverse/transloco'
import { injectInfiniteQuery, injectMutation, injectQueryClient } from '@tanstack/angular-query-experimental'
import { is, isNil, pluck } from 'ramda'
import { firstValueFrom, lastValueFrom } from 'rxjs'

import { ViewHeaderComponent } from '~common/layout/view-header/view-header.component'
import { ViewComponent } from '~common/layout/view/view.component'
import { ShortNumberPipe } from '~common/pipes/short-number.pipe'
import { ButtonComponent } from '~common/ui/button/button.component'
import { DataTableComponent, DataTableSchemaItem } from '~common/ui/data-table/data-table.component'
import { TextComponent } from '~common/ui/text/text.component'
import { TooltipComponent } from '~common/ui/tooltip/tooltip.component'
import { DataTableActionItem, acceptBulkAction, editRecordAction, rejectBulkAction } from '~common/utils/data-table'
import { UserService } from '~core/services'
import { DialogService } from '~core/services/ui/dialog.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'
import {
  addFilterBoolEquals,
  addFilterDateBetweenInclusive,
  addFilterEquals,
  addFilterGte,
  addFilterIContains,
  addFilterStringEquals,
  addFilterStringIn,
  filterByFromFilters,
} from '~core/utils/api'
import logger from '~core/utils/logger'
import { CommentListToolbarComponent } from '~features/comments/components/comment-list-toolbar/comment-list-toolbar.component'
import { CommentListComponent } from '~features/comments/components/comment-list/comment-list.component'
import { CommentsApi } from '~features/comments/services/comments.api'
import { Comment, CommentBulkUpdateRequest, SentimentType } from '~features/comments/types'
import { CommentFilters } from '~features/comments/types'

import { EditCommentDialogComponent } from '../edit-comment-dialog/edit-comment-dialog.component'

@Component({
  selector: 'sb-sentiment-review-list',
  template: `
    <sb-comment-list-toolbar [filters]="toolbarFilters()" (filtersChange)="setToolbarFilters($event)" noChannelFilter />
    <sb-data-table
      [schema]="schema"
      [data]="comments()"
      [displayedColumns]="displayedColumns"
      (loadMore)="onLoadMore()"
      [isFetching]="commentsQuery.isFetching()"
      [actions]="actions"
      (onAction)="handleAction($event)"
      (selected)="selected.set($event)"
      selectable
      stickyHeader
      noCount
      infiniteScroll
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    ViewComponent,
    ViewHeaderComponent,
    CommentListComponent,
    DataTableComponent,
    ShortNumberPipe,
    TextComponent,
    TooltipComponent,
    CommentListToolbarComponent,
    ButtonComponent,
  ],
})
export class SentimentReviewListComponent {
  #commentsApi = inject(CommentsApi)
  initialFilters: CommentFilters = {
    'sentiment.inReview': true,
  }
  hideThreadToggle = input(false, { transform: booleanAttribute })
  selectable = input(false, { transform: booleanAttribute })
  selected = signal<Comment[]>([])
  toolbarFilters = signal<CommentFilters>({
    orderBy: 'publishedAt:-1',
  })
  #userService = inject(UserService)
  #queryClient = injectQueryClient()
  #snackbar = inject(SnackbarService)
  #dialogService = inject(DialogService)

  protected displayedColumns = ['message', 'authorName', 'sentiment', 'proposedSentiment']
  protected schema: DataTableSchemaItem[] = [
    {
      key: 'message',
      label: 'Message',
    },
    {
      key: 'authorName',
      label: 'Author',
    },
    {
      key: 'sentiment',
      label: 'Sentiment',
      textCenter: true,
    },
    {
      key: 'proposedSentiment',
      label: 'Proposed sentiment',
      textCenter: true,
    },
  ]

  actions: DataTableActionItem[] = [editRecordAction(), acceptBulkAction(), rejectBulkAction()]

  commentFilters = computed(() => {
    logger.debug('commentFilters', this.initialFilters, this.toolbarFilters())
    const queryFilters = []
    addFilterIContains(queryFilters, 'message', this.toolbarFilters().message)
    addFilterIContains(queryFilters, 'authorName', this.toolbarFilters().authorName)
    addFilterStringIn(queryFilters, 'sentiment.sentiment', this.toolbarFilters().sentiment)
    addFilterBoolEquals(queryFilters, 'isBookmarked', this.toolbarFilters().isBookmarked)
    addFilterBoolEquals(queryFilters, 'isHidden', this.toolbarFilters().isHidden)

    if (!isNil(this.toolbarFilters().youHaveReplied)) {
      if (this.toolbarFilters().youHaveReplied) {
        addFilterGte(queryFilters, 'channelReplyCount', 1)
      } else {
        addFilterEquals(queryFilters, 'channelReplyCount', 0)
        addFilterEquals(queryFilters, 'parentId', null) // show only root comments
      }
    }

    Object.entries(this.initialFilters).forEach(([key, value]) => {
      if (key === 'since' || key === 'until') {
        return
      } else if (is(String, value)) {
        addFilterStringEquals(queryFilters, key, value)
      } else if (typeof value === 'boolean') {
        addFilterBoolEquals(queryFilters, key, value)
      } else {
        addFilterStringIn(queryFilters, key, value as string[])
      }
    })

    if (this.initialFilters.since && this.initialFilters.until) {
      addFilterDateBetweenInclusive(queryFilters, 'publishedAt', this.initialFilters.since, this.initialFilters.until)
    }

    return {
      base: {
        pageSize: 50,
        orderBy: 'publishedAt:-1',
      },
      filters: {
        ...filterByFromFilters(queryFilters),
        orderBy: this.toolbarFilters().orderBy,
      },
    }
  })

  commentsQuery = injectInfiniteQuery(() => ({
    queryKey: ['comments', this.commentFilters()],
    queryFn: ({ pageParam: pageNumber }) => {
      return lastValueFrom(
        this.#commentsApi.getComments({
          ...this.commentFilters().base,
          ...this.commentFilters().filters,
          pageNumber,
        }),
      )
    },
    initialPageParam: 1,
    getPreviousPageParam: (firstPage) => (firstPage.meta.currentPage > 1 ? firstPage.meta.currentPage - 1 : undefined),
    getNextPageParam: (lastPage) =>
      lastPage.meta.lastPage > lastPage.meta.currentPage ? lastPage.meta.currentPage + 1 : undefined,
  }))

  // bulk update mutation
  bulkUpdateComments = injectMutation(() => ({
    mutationFn: (comments: CommentBulkUpdateRequest['comments']) =>
      firstValueFrom(this.#commentsApi.bulkUpdateComments({ comments })),
    onSuccess: () => {
      this.#queryClient.invalidateQueries({ queryKey: ['comments'] })
      this.#snackbar.open('Comments updated successfully')
    },
  }))

  // flatten comment pages
  comments = computed<Comment[]>(() => {
    let data: Comment[] = []

    if (this.commentsQuery.data()) {
      data = pluck('data', this.commentsQuery.data().pages).flat()
    }

    return data
  })

  onLoadMore = () => {
    if (this.commentsQuery.isFetching() || !this.commentsQuery.hasNextPage()) return
    this.commentsQuery.fetchNextPage()
  }

  setToolbarFilters = (filters: CommentFilters) => {
    this.toolbarFilters.set(filters)
  }

  handleAction = (action: DataTableActionItem) => {
    switch (action.id) {
      case 'ACCEPT_BULK':
        this.bulkUpdateComments.mutate(this.buildAcceptBulkPayload(this.selected()))
        break
      case 'REJECT_BULK':
        this.bulkUpdateComments.mutate(this.buildRejectBulkPayload(this.selected()))
        break
      case 'EDIT_RECORD':
        const dialogRef = this.#dialogService.open(EditCommentDialogComponent, {
          data: {
            comment: this.selected()[0],
          },
        })
        dialogRef.componentInstance.onSave.subscribe(({ comment, proposedSentiment }) => {
          this.bulkUpdateComments.mutate([
            { id: comment.id, sentiment: { sentiment: proposedSentiment, inReview: false } },
          ])
          dialogRef.close()
        })
        break
    }
  }

  buildAcceptBulkPayload = (comments: Comment[]): CommentBulkUpdateRequest['comments'] => {
    return comments.map((comment) => ({
      id: comment.id,
      sentiment: {
        sentiment: comment.proposedSentiment,
        inReview: false,
      },
    }))
  }

  buildRejectBulkPayload = (comments: Comment[]): CommentBulkUpdateRequest['comments'] => {
    return comments.map((comment) => ({
      id: comment.id,
      sentiment: {
        inReview: false,
      },
    }))
  }
}
