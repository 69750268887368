import { Component, OnInit } from '@angular/core'
import { TranslocoModule } from '@jsverse/transloco'
import { LogoComponent } from 'src/app/common/components/logo/logo.component'

@Component({
  selector: 'sb-info-section',
  template: `<div class="mb-9 flex flex-wrap justify-center md:justify-start">
      <div class="lg:1/3 w-1/2">
        <sb-logo class="object-contain" />
      </div>
    </div>
    <div *transloco="let t" class="info-container">
      <div class="info-title">
        {{ t('auth.ui.infoSection.WhatToExpect') }}
      </div>
      <div class="info-item">
        <div class="info-icon">
          <img src="/assets/img/stats.png" />
        </div>
        <div [innerHTML]="t('auth.ui.infoSection.Reports')" class="info-label"></div>
      </div>
      <div class="info-item">
        <div class="info-icon">
          <img src="/assets/img/analytics.png" />
        </div>
        <div [innerHTML]="t('auth.ui.infoSection.Analytics')" class="info-label"></div>
      </div>
      <div class="info-item">
        <div class="info-icon">
          <img src="/assets/img/piggy-bank.png" />
        </div>
        <div [innerHTML]="t('auth.ui.infoSection.Savings')" class="info-label"></div>
      </div>
      <div class="info-item">
        <div class="info-icon">
          <img src="/assets/img/customerly.png" />
        </div>
        <div [innerHTML]="t('auth.ui.infoSection.Support')" class="info-label"></div>
      </div>
    </div>
    <div class="api-partners">
      <img alt="facebook partner" class="mr-4" src="/assets/img/facebook-partner.png" />
      <img alt="linkedin partner" class="" src="/assets/img/linkedin-partner.png" />
    </div> `,
  styles: [
    `
      @use 'mixins/_index.scss' as mixins;

      $padding: 12px;
      $icon-width: 32px;

      .separator {
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
      }

      .info-container {
        font-weight: normal;
        padding: 0 0.5rem;

        &.promo {
          margin-top: 2rem;
        }

        &:not(.promo) {
          display: none;
          @apply md:block;
        }

        .info-title {
          font-size: 1.5rem;
          margin-bottom: 1.5rem;

          @apply lg:mb-8;
        }

        .info-item {
          display: flex;
          flex-wrap: wrap;
          margin-right: -$padding;
          margin-left: -$padding;
          margin-bottom: 2rem;

          @apply lg:mb-8;

          .info-icon {
            position: relative;
            padding-right: $padding;
            padding-left: $padding;
            width: $icon-width + ($padding * 2);
          }

          .info-label {
            position: relative;
            width: 100%;
            padding-right: $padding;
            padding-left: $padding;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            line-height: 1.5;
            font-size: 1rem;

            @include mixins.media-breakpoint-up(lg) {
              font-size: 1.25rem;
            }
          }
        }
      }

      .api-partners {
        display: none;
        text-align: center;

        @apply md:mt-20 md:block;

        img {
          display: inline-block;
          max-width: 150px;
        }
      }
    `,
  ],
  standalone: true,
  imports: [LogoComponent, TranslocoModule],
})
export class InfoSectionComponent {}
