import { Component } from '@angular/core'

@Component({
  selector: 'sb-startup-view',
  standalone: true,
  imports: [],
  template: `
    <div class="flex h-screen w-screen items-center justify-center">
      <img src="/assets/gifs/sbam.gif" alt="Loading..." style="max-width: 120px" />
    </div>
  `,
  styles: ``,
})
export class StartupView {}
