import { provideEffects } from '@ngrx/effects'
import { provideRouterStore } from '@ngrx/router-store'
import { provideStore } from '@ngrx/store'
import { provideStoreDevtools } from '@ngrx/store-devtools'

import { AppConfig } from '~core/config/config'
import { IntegrationEffects } from '~features/integrations/store/effects'
import { FacebookAccountEffects } from '~features/integrations/store/effects/facebook-account.effects'
import { LinkedinAccountEffects } from '~features/integrations/store/effects/linkedin-account.effects'
import { reducers } from '~store'

const EFFECTS = [IntegrationEffects, FacebookAccountEffects, LinkedinAccountEffects]

export const provideAppStore = () => [
  provideEffects(EFFECTS),
  provideStore(reducers, AppConfig.STORE),
  provideRouterStore(AppConfig.STORE_ROUTER),
  provideStoreDevtools(AppConfig.STORE_DEV_TOOLS),
]
