import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslocoModule } from '@jsverse/transloco'

import { Channel } from '~features/projects/models/channel.model'

import { ButtonComponent } from '../../ui/button/button.component'
import { DialogComponent } from '../../ui/dialog/dialog.component'
import { ChannelCardComponent } from '../channels/channel-card/channel-card.component'

@Component({
  selector: 'sb-channel-fetched',
  template: `
    <ng-container *transloco="let t">
      <sb-dialog [title]="t('alerts.channelFetched.Title')">
        <ng-template #content>
          <p>
            {{ t('alerts.channelFetched.Body') }}
          </p>
          <div class="mb-4 mt-3">
            @for (channel of channels; track channel) {
              <sb-channel-card [channel]="channel" [removable]="false"></sb-channel-card>
            }
          </div>
          <p>
            {{ t('alerts.channelFetched.CallToAction') }}
          </p>
        </ng-template>
        <ng-template #actions>
          <sb-button variant="text" (sbClick)="dialogRef.close()">{{ t('actions.Cancel') }}</sb-button>
          <sb-button (sbClick)="reload()">{{ t('actions.RefreshPage') }}</sb-button>
        </ng-template>
      </sb-dialog>
    </ng-container>
  `,
  standalone: true,
  imports: [TranslocoModule, DialogComponent, ChannelCardComponent, ButtonComponent],
})
export class ChannelFetchedComponent {
  channels: Channel[] = []

  constructor(
    public dialogRef: MatDialogRef<ChannelFetchedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { channels: Channel[] },
  ) {
    this.channels = data.channels
  }

  reload() {
    window.location.reload()
  }
}
