import { DataTableSchemaItem } from 'src/app/common/ui/data-table/data-table.component'

const schema: DataTableSchemaItem[] = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'firstName',
    label: 'First Name',
  },
  {
    key: 'lastName',
    label: 'Last Name',
  },
  {
    key: 'plan.label',
    label: 'Plan',
  },
  {
    key: 'hasPaymentMethod',
    label: 'Has payment method',
  },
  {
    key: 'projectCount',
    label: 'Projects',
  },
  {
    key: 'igAccountCount',
    label: 'IG Account',
  },
  {
    key: 'fbPageCount',
    label: 'FB Pages',
  },
  {
    key: 'fbAdAccountCount',
    label: 'FB Ad Accounts',
  },
  {
    key: 'actions',
    number: true,
  },
]

export default schema
