import { NgModule } from '@angular/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
  providers: [MatIconRegistry],
})
export class IconsModule {
  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
  ) {
    this.registerIcons()
  }

  public registerIcons(): void {
    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icon-set.svg`))
  }
}
