import { Routes } from '@angular/router'

import { authenticationGuard, authorizationGuard } from '~core/guards'

import { IntegrationsView } from './views/integrations/integrations.view'

export const INTEGRATIONS_ROUTES: Routes = [
  {
    path: 'integrations',
    component: IntegrationsView,
    canActivate: [authorizationGuard, authenticationGuard],
  },
]
