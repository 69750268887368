import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ApplicationConfig, ErrorHandler } from '@angular/core'
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular'

import { GlobalErrorHandler } from '~core/global-error-handler'
import { ErrorInterceptor } from '~core/interceptors/error.interceptor'
import { LoadingInterceptor } from '~core/interceptors/loading.interceptor'
import { rollbarFactory, RollbarService } from '~core/services/rollbar.service'

export const provideCore = (): ApplicationConfig['providers'] => [
  { provide: ErrorHandler, useClass: GlobalErrorHandler },
  { provide: RollbarService, useFactory: rollbarFactory },
  { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
]
