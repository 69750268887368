import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ActivatedRoute } from '@angular/router'
import { ViewHeaderComponent } from 'src/app/common/layout/view-header/view-header.component'
import { ViewComponent } from 'src/app/common/layout/view/view.component'
import { BoxComponent } from 'src/app/common/ui/box/box.component'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'

import { SnackbarService } from '~core/services/ui/snackbar.service'
import { environment } from '~env'

@Component({
  selector: 'sb-mautic',
  standalone: true,
  imports: [
    ViewComponent,
    ViewHeaderComponent,
    BoxComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
  ],
  template: `
    <sb-view>
      <ng-template #toolbar>
        <sb-view-header [title]="'Mautic Authorization'" />
      </ng-template>
      <ng-template #content>
        <form (ngSubmit)="onSubmit()" [formGroup]="mauticForm">
          <div class="inline-flex w-full items-center gap-2 py-4">
            <mat-form-field class="grow" subscriptSizing="dynamic">
              <mat-label>Client ID (Public key)</mat-label>
              <input matInput formControlName="client_id" id="client_id" readonly type="text" />
            </mat-form-field>
            <sb-button [disabled]="!mauticForm.valid" type="submit">Authenticate</sb-button>
          </div>
        </form>
      </ng-template>
    </sb-view>
  `,
})
export class MauticComponent implements OnInit {
  mauticForm: UntypedFormGroup
  mauticState: string
  tokenEndpoint = environment.apiEndpoint + '/' + environment.v1 + '/mautic/token'

  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    // init form
    this.mauticForm = new UntypedFormGroup({
      client_id: new UntypedFormControl(environment.mauticPublicKey, Validators.required),
      grant_type: new UntypedFormControl('authorization_code', Validators.required),
      redirect_uri: new UntypedFormControl(environment.appUrl + '/admin/mautic', Validators.required),
      response_type: new UntypedFormControl('code', Validators.required),
      state: new UntypedFormControl(null, Validators.required),
    })

    this.route.queryParamMap.subscribe((params) => {
      if (params.has('state') && params.has('code')) {
        // check status
        const state = params.get('state').trim()
        this.mauticState = localStorage.getItem('mauticState').trim()
        if (state !== this.mauticState) {
          this.generateState()
          this.snackbarService.error('Invalid mautic state')
          return
        }

        // call backend api for token request and save
        this.httpClient
          .post(this.tokenEndpoint, {
            code: params.get('code'),
          })
          .subscribe(
            () => this.snackbarService.success('Auth token obtained'),
            () => this.snackbarService.error('Error getting auth token'),
          )
      } else {
        this.generateState()
        this.mauticForm.controls.state.setValue(this.mauticState)
      }
    })
  }

  onSubmit() {
    if (this.mauticForm.valid) {
      const params = this.mauticForm.value
      const urlParams = new URLSearchParams()
      for (const key in params) {
        urlParams.set(key, params[key])
      }

      window.location.href = 'https://mautic.sbam.io/oauth/v2/authorize?' + urlParams.toString()
    }
  }

  private generateState() {
    // generate state code
    this.mauticState = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
    localStorage.setItem('mauticState', this.mauticState)
  }
}
