import { NgClass } from '@angular/common'
import { Component, computed, inject, signal } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator'
import { TranslocoModule } from '@jsverse/transloco'
import { injectQuery } from '@tanstack/angular-query-experimental'
import { lastValueFrom } from 'rxjs'
import { ViewHeaderComponent } from 'src/app/common/layout/view-header/view-header.component'
import { ViewComponent } from 'src/app/common/layout/view/view.component'

import { SearchBarComponent } from '~common/components/filters/search-bar/search-bar.component'
import logger from '~core/utils/logger'
import { environment } from '~env'
import { UserListComponent } from '~features/user/components/user-list/user-list.component'
import { User } from '~features/user/models/user.model'

import { AdminService } from '../../admin.service'

@Component({
  selector: 'sb-support',
  standalone: true,
  imports: [
    ViewComponent,
    ViewHeaderComponent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    UserListComponent,
    SearchBarComponent,
    MatPaginatorModule,
  ],
  template: `
    <sb-view>
      <ng-template #toolbar>
        <sb-view-header [title]="'Users'" />
      </ng-template>
      <ng-template #content>
        <sb-search-bar (search)="onSearch($event)" [debounce]="600" />
        <sb-user-list [data]="users()" (login)="onLoginAsUser($event)" />
        <mat-paginator
          [length]="usersQuery.data()?.meta.total"
          [pageSize]="15"
          [pageIndex]="filters().page - 1"
          (page)="onChangePage($event)"
        />
      </ng-template>
    </sb-view>
  `,
})
export class SupportView {
  disabled = false
  filters = signal<{ page: number; search: string }>({ page: 1, search: '' })
  adminService = inject(AdminService)

  usersQuery = injectQuery(() => ({
    queryKey: ['admin-users', this.filters()],
    queryFn: () => lastValueFrom(this.adminService.users(this.filters())),
  }))

  users = computed<User[]>(() => {
    return this.usersQuery.data()?.data.map((user: User) => new User(user))
  })

  onChangePage(event: PageEvent) {
    this.filters.set({ ...this.filters(), page: event.pageIndex + 1 })
    logger.debug('onChangePage', event)
  }

  onLoginAsUser({ _id }: User) {
    this.disabled = true
    this.adminService.loginAsUser(_id).subscribe(() => location.replace(environment.appUrl))
  }

  onSearch(search: string) {
    this.filters.set({ search, page: 1 })
  }
}
