import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators'

import { JsonResponse } from '~core/models/json-response.model'
import { UserService } from '~core/services'
import * as CoreActions from '~core/store/core.actions'
import { truthy } from '~core/utils/operators'
import { LinkedinAccount } from '~features/integrations/models/linkedin-account.model'
import { LinkedinAccountService } from '~features/integrations/services/linkedin-account.service'

import * as IntegrationActions from '../actions'

@Injectable()
export class LinkedinAccountEffects {
  deleteLinkedinAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.deleteLinkedinAccount),
      exhaustMap(({ payload }) =>
        this.linkedinAccountService.delete(payload._id).pipe(
          map(() => IntegrationActions.deleteLinkedinAccountSuccess()),
          catchError((err: HttpErrorResponse) => of(IntegrationActions.deleteLinkedinAccountError(err))),
        ),
      ),
    )
  })

  deleteLinkedinAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IntegrationActions.deleteLinkedinAccountSuccess),
        tap(() => this.userService.populate()),
      )
    },
    { dispatch: false },
  )

  loadLinkedinAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.loadLinkedinAccounts),
      switchMap(this.linkedinAccountService.getAll),
      map((linkedinAccounts) => IntegrationActions.loadLinkedinAccountsSuccess(linkedinAccounts)),
    )
  })

  loadLinkedinAccountsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.loadLinkedinAccountsSuccess),
      map(({ payload }: { payload: JsonResponse<LinkedinAccount[]> }) => payload.data),
      map((linkedinAccounts: LinkedinAccount[]) => linkedinAccounts.find((f: LinkedinAccount) => f.is_primary)),
      truthy(),
      map(({ _id }: LinkedinAccount) => IntegrationActions.setActiveLinkedinAccount({ _id })),
    )
  })

  makePrimaryLinkedinAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.makePrimaryLinkedinAccount),
      exhaustMap(({ payload }) =>
        this.linkedinAccountService.makePrimary(payload._id).pipe(
          map(() => IntegrationActions.loadLinkedinAccounts()),
          catchError((err: HttpErrorResponse) => of(IntegrationActions.makePrimaryLinkedinAccountError(err))),
        ),
      ),
    )
  })

  refreshLinkedinAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.refreshLinkedinAccount),
      exhaustMap(({ payload }) =>
        this.linkedinAccountService.refreshToken(payload).pipe(
          map((response) => IntegrationActions.refreshLinkedinAccountSuccess(response)),
          catchError((err: HttpErrorResponse) => of(IntegrationActions.refreshLinkedinAccountError(err))),
        ),
      ),
    )
  })

  refreshLinkedinAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IntegrationActions.refreshLinkedinAccountSuccess),
        tap(({ payload }) => this.userService.refreshLinkedinAccountChannels(payload.data)),
      )
    },
    { dispatch: false },
  )
  storeLinkedinAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.storeLinkedinAccount),
      exhaustMap(({ payload }) =>
        this.linkedinAccountService.store(payload).pipe(
          map((response) => IntegrationActions.storeLinkedinAccountSuccess(response)),
          catchError((err: HttpErrorResponse) => of(IntegrationActions.storeLinkedinAccountError(err))),
        ),
      ),
    )
  })
  storeLinkedinAccountError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.storeLinkedinAccountError),
      map(() => CoreActions.setLockedIsLoading({ payload: false })),
    )
  })
  storeLinkedinAccountSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.storeLinkedinAccountSuccess),
      map(({ payload }) => IntegrationActions.setActiveLinkedinAccount({ _id: payload.data._id })),
    )
  })

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private linkedinAccountService: LinkedinAccountService,
  ) {}
}
