import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ActivatedRoute, ParamMap, RouterLink } from '@angular/router'
import { TranslocoModule } from '@jsverse/transloco'
import { Subscription } from 'rxjs'
import { LogoComponent } from 'src/app/common/components/logo/logo.component'
import { Icon } from 'src/app/common/icons'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { CardComponent } from 'src/app/common/ui/card/card.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { ApiService } from '~core/services'

@Component({
  selector: 'sb-reset-password',
  template: `<ng-container *transloco="let t; read: 'reset'">
    <div class="bg-surface">
      <div class="container">
        <div class="flex h-screen flex-wrap items-center p-5">
          <div class="order-first mx-auto mb-3 mt-9 w-full px-5 md:order-last md:mb-0 md:w-1/2">
            <sb-logo class="object-contain" />
          </div>
          <div class="my-5 w-full md:order-first md:w-4/12">
            @if (!submitted) {
              <sb-text variant="title" align="center" class="mb-0">{{ t('title') }}</sb-text>
              <sb-text variant="subtitle" align="center" muted class="mb-5">{{ t('subtitle') }}</sb-text>
              <form (ngSubmit)="onResetPassword()" [formGroup]="resetPasswordForm" class="grid">
                <mat-form-field>
                  <mat-label>{{ t('form.password') }}</mat-label>
                  <!-- Input group -->
                  <input
                    matInput
                    [placeholder]="t('form.passwordPlaceholder')"
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                    id="password"
                    name="password"
                  />
                  <sb-button
                    variant="icon"
                    matSuffix
                    [icon]="showPassword ? Icons.VisibilityOff : Icons.Visibility"
                    (sbClick)="showPassword = !showPassword"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="!showPassword"
                  />
                  @if (!resetPasswordForm.get('password').valid && resetPasswordForm.get('password').touched) {
                    <mat-error>
                      @if (resetPasswordForm.get('password').errors['required']) {
                        {{
                          'validation.required'
                            | transloco
                              : {
                                  attribute: 'reset.form.password' | transloco
                                }
                        }}
                      }
                      @if (
                        resetPasswordForm.get('password').errors['pattern'] ||
                        resetPasswordForm.get('password').errors['minLength']
                      ) {
                        {{
                          'validation.regex'
                            | transloco
                              : {
                                  attribute: 'reset.form.password' | transloco
                                }
                        }}
                      }
                    </mat-error>
                  }
                </mat-form-field>
                <sb-card class="mb-5" [title]="t('requirements.title')">
                  <ng-template #content>
                    <sb-text>{{ t('requirements.subtitle') }}:</sb-text>
                    <ul class="mb-0 pl-4">
                      <li>
                        <sb-text noMargin>{{ t('requirements.length') }}</sb-text>
                      </li>
                      <li>
                        <sb-text noMargin>{{ t('requirements.uppercase') }}</sb-text>
                      </li>
                      <li>
                        <sb-text noMargin>{{ t('requirements.lowercase') }}</sb-text>
                      </li>
                    </ul>
                  </ng-template>
                </sb-card>
                <sb-button [disabled]="!resetPasswordForm.valid" fill type="submit"> {{ t('form.button') }} </sb-button>
              </form>
            } @else {
              <sb-text variant="title" class="mb-3">{{ t('afterReset.title') }}</sb-text>
              <sb-text>{{ t('afterReset.info') }}</sb-text>
              <sb-button fill routerLink="/login">{{ t('afterReset.signin') }}</sb-button>
            }
            <ng-template #info>
              <sb-text variant="title" class="mb-3">{{ t('afterReset.title') }}</sb-text>
              <sb-text>{{ t('afterReset.info') }}</sb-text>
              <sb-button fill routerLink="/login">{{ t('afterReset.signin') }}</sb-button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container> `,
  standalone: true,
  imports: [
    TranslocoModule,
    LogoComponent,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    CardComponent,
    RouterLink,
  ],
})
export class ResetPasswordView implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup
  showPassword: boolean
  submitted = false
  protected Icons = Icon
  private paramSubscription = Subscription.EMPTY
  private querySubscription = Subscription.EMPTY

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
  ) {}

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe()
    this.querySubscription.unsubscribe()
  }

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8), // must be at least 8 characters
        Validators.pattern(/[A-Z]/), // must contain at least one lowercase
        Validators.pattern(/[a-z]/), // must contain at least one uppercase
      ]),
      token: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
    })

    this.paramSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      this.resetPasswordForm.controls['token'].setValue(params.get('token'))
    })

    this.querySubscription = this.route.queryParamMap.subscribe((params) => {
      this.resetPasswordForm.controls['email'].setValue(params.get('email'))
    })
  }

  onResetPassword() {
    const body = {
      email: this.resetPasswordForm.value.email,
      password: this.resetPasswordForm.value.password,
      token: this.resetPasswordForm.value.token,
    }
    this.apiService.post('/v1.0/auth/reset', body).subscribe(() => (this.submitted = true))
  }
}
