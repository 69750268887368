import { ViewportScroller } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, RouterLink } from '@angular/router'
import { TranslocoModule } from '@jsverse/transloco'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'sb-ig-account-help',
  template: `<div class="main-content">
    <ng-container *transloco="let _; read: 'troubleshooting'">
      <div class="container">
        <div class="header">
          <div class="header-body">
            <div class="row align-items-end">
              <div class="col">
                <h1 class="header-title">
                  {{ _('title') }}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <p>
                      <span [innerHTML]="_('intro.text')"></span>
                      <a routerLink="/troubleshooting" fragment="convert">{{ _('intro.conversionAnchor') }}</a
                      >.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="admin" class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h2>{{ _('admin.title') }}</h2>
                    <ul>
                      <li>{{ _('admin.stepOne') }}</li>
                      <li>
                        {{ _('admin.stepTwo.text') }}
                        <ol>
                          <li>{{ _('admin.stepTwo.list.1') }}</li>
                          <li class="my-3">{{ _('admin.stepTwo.list.2') }}</li>
                          <li class="my-3">{{ _('admin.stepTwo.list.3') }}</li>
                        </ol>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12">
                    <img
                      src="/assets/img/troubleshooting/Facebook-page-roles.jpg"
                      alt="fb-pics"
                      class="shadow img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="connect" class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h2>{{ _('connect.title') }}</h2>
                    <p>{{ _('connect.intro') }}</p>
                    <ul>
                      <li [innerHTML]="_('connect.stepOne')"></li>
                      <li [innerHTML]="_('connect.stepTwo')"></li>
                      <li>
                        {{ _('connect.stepThree.text') }}
                        <ol>
                          <li>
                            <div>
                              <b>{{ _('connect.stepThree.list.1.title') }}</b>
                            </div>
                            <div [innerHTML]="_('connect.stepThree.list.1.text')"></div>
                          </li>
                          <li>
                            <div>
                              <b>{{ _('connect.stepThree.list.2.title') }}</b>
                            </div>
                            <div [innerHTML]="_('connect.stepThree.list.2.text')"></div>
                          </li>
                          <li>
                            <div>
                              <b>{{ _('connect.stepThree.list.3.title') }}</b>
                            </div>
                            <div [innerHTML]="_('connect.stepThree.list.3.text')"></div>
                          </li>
                        </ol>
                      </li>
                      <li class="my-3" [innerHTML]="_('connect.stepFour')"></li>
                    </ul>
                  </div>
                  <div class="col-12">
                    <img
                      src="/assets/img/troubleshooting/Facebook-verify-instagram-connection.jpg"
                      alt="fb-pics"
                      class="shadow img-fluid rounded"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="convert" class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h2>{{ _('convert.title') }}</h2>
                    <p>{{ _('convert.intro') }}</p>
                    <ul>
                      <li class="my-3" [innerHTML]="_('convert.stepOne')"></li>
                      <li class="my-3" [innerHTML]="_('convert.stepTwo')"></li>
                      <li class="my-3" [innerHTML]="_('convert.stepThree')"></li>
                      <li class="my-3" [innerHTML]="_('convert.stepFour')"></li>
                      <li class="my-3" [innerHTML]="_('convert.stepFive')"></li>
                      <li class="my-3" [innerHTML]="_('convert.stepSix')"></li>
                    </ul>
                  </div>
                  <div class="col-12">
                    <div class="alert alert-warning" role="alert" [innerHTML]="_('convert.alert')"></div>
                  </div>
                  <div class="col">
                    <div class="row justify-content-around">
                      <div class="col-sm-6">
                        <img
                          src="/assets/img/troubleshooting/Instagram-choose-professional-account.jpg"
                          alt="ig-pro-account"
                          class="shadow img-fluid rounded"
                        />
                      </div>
                      <div class="col-sm-6">
                        <img
                          src="/assets/img/troubleshooting/Instagram-switch-to-professional.jpg"
                          alt="fb-pics"
                          class="shadow img-fluid rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div> `,
  styles: [
    `
      a {
        border-bottom: 1px solid #06667c;
      }
    `,
  ],
  standalone: true,
  imports: [TranslocoModule, RouterLink],
})
export class IgAccountHelpComponent implements OnInit, OnDestroy {
  private subscription = Subscription.EMPTY

  constructor(
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.subscription = this.route.fragment.pipe(filter((fragment) => !!fragment)).subscribe((fragment) => {
      setTimeout(() => this.scroll(fragment), 250)
    })
  }

  scroll(anchor: string) {
    this.viewportScroller.scrollToAnchor(anchor)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
