import { Component, Input, output } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { TranslocoModule } from '@jsverse/transloco'
import { TranslocoLocaleModule } from '@jsverse/transloco-locale'
import { ChannelIconsComponent } from 'src/app/common/components/channel-icons/channel-icons.component'
import { ClickStopPropagationDirective } from 'src/app/common/directives/click-stop-propagation.directive'
import { IconFill } from 'src/app/common/icons'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { DataTableComponent } from 'src/app/common/ui/data-table/data-table.component'
import { MenuComponent } from 'src/app/common/ui/menu/menu.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { Helper } from '~core/services'
import { User } from '~features/user/models/user.model'

import userListSchema from './user-list.schema'

type Option = {
  label: string
  action: 'LOGIN'
}

@Component({
  selector: 'sb-user-list',
  template: `
    <ng-container *transloco="let t">
      <sb-data-table [data]="data" [schema]="schema" [displayedColumns]="displayedColumns" stickyHeader noCount>
        <ng-template #cellTemplate let-user let-schema="schema">
          @switch (schema.key) {
            @case ('hasPaymentMethod') {
              <div class="align-center flex justify-center">
                @if (user.hasPaymentMethod) {
                  <mat-icon color="primary" [svgIcon]="Icons.CheckCircle"></mat-icon>
                } @else {
                  <mat-icon [svgIcon]="Icons.Cancel"></mat-icon>
                }
              </div>
            }
            @case ('plan.label') {
              {{ user.plan?.label }}
            }
            @case ('projectCount') {
              {{ user.projects?.length }}
            }
            @case ('fbPageCount') {
              {{ user.getChannelsCountByType('facebook-page') }}
            }
            @case ('igAccountCount') {
              {{ user.getChannelsCountByType('instagram-account') }}
            }
            @case ('fbAdAccountCount') {
              {{ user.getChannelsCountByType('facebook-ad-account') }}
            }
            @case ('actions') {
              <sb-button variant="square" (sbClick)="onActionSelection(selectOptions[0], user)" [icon]="Icons.Login">{{
                selectOptions[0].label
              }}</sb-button>
            }
            @default {
              {{ user[schema.key] || '-' }}
            }
          }
        </ng-template>
      </sb-data-table>
    </ng-container>
  `,
  styles: [],
  standalone: true,
  imports: [
    TranslocoModule,
    DataTableComponent,
    TextComponent,
    ChannelIconsComponent,
    MenuComponent,
    ClickStopPropagationDirective,
    ButtonComponent,
    TranslocoLocaleModule,
    MatIcon,
  ],
})
export class UserListComponent {
  @Input() data: User[]
  login = output<User>()
  selectOptions: Option[] = [
    {
      label: 'Login as user',
      action: 'LOGIN',
    },
  ]
  protected readonly displayedColumns: string[] = [
    'email',
    'firstName',
    'lastName',
    'plan.label',
    'hasPaymentMethod',
    'projectCount',
    'fbPageCount',
    'igAccountCount',
    'fbAdAccountCount',
    'actions',
  ]
  protected readonly schema = userListSchema
  protected readonly Icons = IconFill

  constructor(public helper: Helper) {}

  onActionSelection(option: Option, user: User) {
    switch (option.action) {
      case 'LOGIN':
        this.login.emit(user)
        break
    }
  }
}
