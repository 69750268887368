import { NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { SafeHtml } from '@angular/platform-browser'
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@jsverse/transloco'
import { of, Subscription } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { LogoComponent } from 'src/app/common/components/logo/logo.component'
import { Icon } from 'src/app/common/icons'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { DialogComponent } from 'src/app/common/ui/dialog/dialog.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { UserService } from '~core/services'
import { FacebookApiService } from '~core/services/facebook-api.service'
import { DialogService } from '~core/services/ui/dialog.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'
import { environment } from '~env'

@Component({
  selector: 'sb-signin',
  template: `<ng-container *transloco="let t; read: 'signin'">
      <!--  <div class="bg-surface container">-->
      <div class="bg-surface">
        <div class="container">
          <div class="flex h-screen flex-wrap items-center p-5">
            <div class="order-first mx-auto mb-3 mt-9 w-full px-5 md:order-last md:mb-0 md:w-1/2">
              <sb-logo class="object-contain" />
            </div>
            <div class="my-5 w-full md:order-first md:w-4/12">
              <sb-text variant="title" align="center" class="mb-9">{{ t('title') }}</sb-text>
              <form (ngSubmit)="onSignIn(dialogFb)" [formGroup]="signInForm" class="grid">
                <sb-button variant="outlined" [icon]="Icons.Facebook" (sbClick)="onClickFbLogin()" fill class="mb-5">
                  {{ t('facebookLogin') }}
                </sb-button>
                <sb-text variant="body" [error]="invalidCredentials">
                  <span [innerHTML]="statusMessage"></span>
                </sb-text>
                <!-- Email address -->
                <mat-form-field>
                  <mat-label>{{ t('form.email') }}</mat-label>
                  <input
                    matInput
                    formControlName="email"
                    id="email"
                    required
                    [placeholder]="t('form.emailPlaceholder')"
                    type="email"
                  />
                  <mat-error *ngIf="!signInForm.get('email').valid && signInForm.get('email').touched">
                    <ng-container *ngIf="signInForm.get('email').errors['required']">
                      {{ 'validation.required' | transloco: { attribute: 'signin.form.email' | transloco } }}
                    </ng-container>
                    <ng-container *ngIf="signInForm.get('email').errors['email']">
                      {{ 'validation.regex' | transloco: { attribute: 'signin.form.email' | transloco } }}
                    </ng-container>
                  </mat-error>
                </mat-form-field>
                <!-- Password -->
                <sb-text variant="label" align="end">
                  <a class="form-text small text-muted" routerLink="/forgot-password" tabindex="-1">
                    {{ t('forgotPassword') }}
                  </a>
                </sb-text>
                <mat-form-field>
                  <mat-label>{{ t('form.password') }}</mat-label>
                  <!-- Input group -->
                  <input
                    matInput
                    [placeholder]="t('form.passwordPlaceholder')"
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                    id="password"
                    name="password"
                  />
                  <sb-button
                    variant="icon"
                    matSuffix
                    [icon]="showPassword ? Icons.VisibilityOff : Icons.Visibility"
                    (click)="onShowPassword()"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="!showPassword"
                  />
                  <mat-error *ngIf="!signInForm.get('password').valid && signInForm.get('password').touched">
                    {{ 'validation.required' | transloco: { attribute: 'signin.form.password' | transloco } }}
                  </mat-error>
                </mat-form-field>
                <sb-button fill type="submit" class="mt-3"> {{ t('form.button') }} </sb-button>
                <sb-text variant="label" align="center" class="mt-3" muted>
                  {{ t('doNotHaveAnAccount') }} <a routerLink="/register">{{ t('signup') }}</a
                  >.
                </sb-text>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- / .container -->
    </ng-container>

    <ng-template #dialogFb>
      <sb-dialog title="Hi Facebook Tester!">
        <ng-template #content>
          <sb-text>
            Since this is a published app we cannot test new features on the production environment. Please access the
            staging environment, as we indicated in the app review request details. This is the url:
            <a href="https://staging.app.sbam.io">https://staging.app.sbam.io</a>
          </sb-text>
        </ng-template>
      </sb-dialog>
    </ng-template>

    <ng-template #invalidFacebookLogin>
      <sb-dialog title="Ops! Qualcosa non va...">
        <ng-template #content>
          <sb-text>
            Sembra che il tuo account Facebook non sia associato al nessun account su Sbam oppure non è abilitato per il
            login!
          </sb-text>
          <sb-text>
            Se ti sei già registrato fai il login con email e password per completare la procedura di associazione.
          </sb-text>
        </ng-template>
        <ng-template #actions>
          <sb-button variant="outlined" (click)="invalidFBLoginDialogRef.close()">Login</sb-button>
          <sb-button (click)="onClickGoToRegister()">Registrati</sb-button>
        </ng-template>
      </sb-dialog>
    </ng-template> `,
  standalone: true,
  imports: [
    TranslocoModule,
    LogoComponent,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    RouterLink,
    DialogComponent,
  ],
})
export class SigninView implements OnInit, OnDestroy {
  dialogRef: MatDialogRef<any>
  icon: SafeHtml
  invalidCredentials: boolean
  invalidFBLoginDialogRef: MatDialogRef<any>
  @ViewChild('invalidFacebookLogin') invalidFBLoginDialogTemplate: TemplateRef<any>
  isLoading = false
  returnUrl: string
  showPassword: boolean
  signInForm: FormGroup
  statusMessage: string
  private facebookLoginSubscription = Subscription.EMPTY
  private facebookRedirectUri: string
  protected Icons = Icon

  constructor(
    private facebookService: FacebookApiService,
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslocoService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {}

  ngOnDestroy() {
    this.facebookLoginSubscription.unsubscribe()
  }

  ngOnInit() {
    this.facebookRedirectUri = window.location.origin + window.location.pathname
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
    this.signInForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
    })
    this.showPassword = false
    this.subscribeToFacebookLogin()
  }

  onClickFbLogin() {
    this.isLoading = true
    this.facebookService.login(this.facebookRedirectUri, null)
  }

  onClickGoToRegister() {
    this.router.navigate(['/register']).then(() => this.invalidFBLoginDialogRef.close())
  }

  onShowPassword() {
    this.showPassword = !this.showPassword
  }

  onSignIn(dialogFb: TemplateRef<any>) {
    if (this.signInForm.invalid) {
      this.signInForm.markAllAsTouched()
      return
    }
    this.invalidCredentials = false
    this.statusMessage = 'Signing in...'
    if (this.signInForm.value.email === 'test@sbam.io' && environment.production) {
      this.statusMessage = 'Please log in from <a href="https://staging.app.sbam.io">https://staging.app.sbam.io</a>'
      this.dialogRef = this.dialogService.open(dialogFb)
      return
    }
    this.userService.attemptAuth(this.signInForm.value).subscribe({
      next: () => this.router.navigateByUrl(this.returnUrl),
      error: (error) => {
        let message = ''
        switch (error.status) {
          case 401: // invalid credentials
            message = this.translateService.translate('signin.invalidCredentials')
            break
          case 503: // maintenance
            return this.router.navigateByUrl('/maintenance')
          default:
            message = this.translateService.translate('alerts.somethingGoesWrong')
            break
        }
        this.statusMessage = message
        this.invalidCredentials = true
      },
    })
  }

  removeQueryParams() {
    this.router.navigate([this.router.url.split('?')[0]])
  }

  subscribeToFacebookLogin() {
    this.facebookLoginSubscription = this.route.queryParamMap
      .pipe(
        switchMap((queryParams: ParamMap) => {
          if (!queryParams.has('granted_scopes') || !queryParams.has('code')) {
            this.removeQueryParams()
            return of(false)
          }
          this.isLoading = true
          const grantedScopes = queryParams.get('granted_scopes').split(',')
          this.facebookService.checkScopes(['email'], grantedScopes)
          return this.userService.facebookLogin(queryParams.get('code'), this.facebookRedirectUri)
        }),
        filter((isValid) => !!isValid),
      )
      .subscribe({
        next: () => {
          this.removeQueryParams()
          this.router.navigate([this.returnUrl])
        },
        error: (error) => {
          this.removeQueryParams()
          if (error instanceof Error) {
            this.snackbarService.error(error.message)
          }
          let message = ''
          switch (error.status) {
            case 401: // invalid credentials
              this.invalidFBLoginDialogRef = this.dialogService.open(this.invalidFBLoginDialogTemplate)
              break
            case 503: // maintenance
              return this.router.navigate(['maintenance'])
            default:
              message = 'Ops! Qualcosa è andato storto!'
              break
          }
          this.isLoading = false
          this.statusMessage = message
          this.invalidCredentials = true
        },
      })
  }
}
