import { Component, Input, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import { TranslocoModule } from '@jsverse/transloco'

@Component({
  selector: 'sb-not-found',
  template: `<ng-container *transloco="let _; read: 'notFound'">
    <div class="container">
      <div class="row justify-content-center align-items-center vh-100">
        <div class="col-12 col-md-5 col-xl-4 my-5">
          <div class="text-center">
            <!-- Preheading -->
            <h6 class="text-uppercase text-muted mb-4">
              {{ _('error') }}
            </h6>

            <!-- Heading -->
            <h1 class="display-4 mb-3">
              {{ _('title') }}
            </h1>

            <!-- Subheading -->
            <p class="text-muted mb-4">
              {{ _('subtitle') }}
            </p>

            <!-- Button -->
            @if (showButton) {
              <a [routerLink]="['/']" class="btn btn-lg btn-primary">
                {{ _('backToDashboard') }}
              </a>
            }
          </div>
        </div>
      </div>
    </div>
    <!-- / .container -->
  </ng-container> `,
  standalone: true,
  imports: [TranslocoModule, RouterLink],
})
export class NotFoundComponent {
  @Input() showButton = true
}
