import { Routes } from '@angular/router'

import { noAuthGuard } from './services/no-auth.guard'
import { ForgotPasswordView } from './views/forgot-password/forgot-password.view'
import { ResetPasswordView } from './views/reset-password/reset-password.view'
import { SigninView } from './views/signin/signin.view'
import { SignupView } from './views/signup/signup.view'

export const AUTH_ROUTES: Routes = [
  { path: 'reset-password/:token', component: ResetPasswordView, canActivate: [noAuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordView, canActivate: [noAuthGuard] },
  { path: 'register/:coupon', component: SignupView, canActivate: [noAuthGuard] },
  { path: 'register', component: SignupView, canActivate: [noAuthGuard] },
  { path: 'login', component: SigninView, canActivate: [noAuthGuard] },
]
