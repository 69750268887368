import { inject } from '@angular/core'
import { CanActivateFn, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { UserService } from '~core/services'

export const noAuthGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router)
  const userService = inject(UserService)

  return userService.isAuthenticated.pipe(
    take(1),
    map((isAuth) => (isAuth ? router.createUrlTree(['/']) : true)),
  )
}
