import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { ApiService, JwtService, UserService } from '~core/services'
import { environment } from '~env'

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  url = environment.apiEndpoint + '/' + environment.v1

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private jwtService: JwtService,
  ) {}

  loginAsUser(_id: string) {
    return this.apiService
      .post('/v1.0/auth/login-as-user', {
        id: _id,
      })
      .pipe(
        tap((response) => {
          // Save JWT sent from server in localstorage
          this.jwtService.saveToken(response.access_token)
        }),
      )
  }

  users(params: Record<string, string | number> = {}): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params })
    return this.apiService.get('/v1.0/users', httpParams)
  }
}
