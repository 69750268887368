import { ChangeDetectionStrategy, Component } from '@angular/core'

import { ViewHeaderComponent } from '~common/layout/view-header/view-header.component'
import { ViewComponent } from '~common/layout/view/view.component'
import { SentimentReviewListComponent } from '~features/admin/components/sentiment-review-list/sentiment-review-list.component'

@Component({
  selector: 'sb-sentiment-review',
  standalone: true,
  imports: [ViewComponent, ViewHeaderComponent, SentimentReviewListComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sb-view title="Sentiment review">
      <sb-sentiment-review-list />
    </sb-view>
  `,
})
export class SentimentReviewView {}
