import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { inject } from '@angular/core'
import { Observable } from 'rxjs'

import { JwtService } from '~core/services'
import { environment } from '~env'

const needsToken = (request: HttpRequest<any>) => {
  if (!request.url.includes(environment.apiEndpoint)) {
    return false
  }

  return (
    !request.url.includes('auth') ||
    request.url.includes('logout') ||
    request.url.includes('refresh') ||
    request.url.includes('me') ||
    request.url.includes('login-as-user') ||
    request.url.includes('broadcasting/auth')
  )
}

export const jwtInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const jwtService = inject(JwtService)
  const headersConfig = {
    Accept: 'application/json',
    'Sbam-App-Url': window.location.href,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  }

  if (request.method !== 'POST') {
    headersConfig['Content-Type'] = 'application/json'
  }

  const token = jwtService.getToken()

  if (needsToken(request) && !!token) {
    headersConfig['Authorization'] = `Bearer ${token}`
  }

  request = request.clone({ setHeaders: headersConfig })
  return next(request)
}
