import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslocoModule } from '@jsverse/transloco'
import { ChannelListComponent } from 'src/app/common/components/channels/channel-list/channel-list.component'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { DialogComponent } from 'src/app/common/ui/dialog/dialog.component'

import { FacebookAccount } from '~features/integrations/models/facebook-account.model'
import { LinkedinAccount } from '~features/integrations/models/linkedin-account.model'
import { Channel } from '~features/projects/models/channel.model'

type Integration = FacebookAccount | LinkedinAccount
type IntegrationType = 'facebook-account' | 'linkedin-account'
type InputData = { type: IntegrationType; integration: Integration }

@Component({
  selector: 'sb-confirm-integration-delete',
  template: `
    <ng-container *transloco="let t">
      <sb-dialog [title]="t('integrations.info.ThisActionIsIrreversible')">
        <ng-template #content>
          <p [innerHTML]="t('integrations.info.DeleteDisclaimer')"></p>
          <sb-channel-list [channels]="channels" [clickable]="false" />
        </ng-template>
        <ng-template #actions>
          <sb-button variant="text" (sbClick)="dialogRef.close()">{{ t('actions.Cancel') }}</sb-button>
          <sb-button (sbClick)="onConfirmDelete()">{{ t('actions.Confirm') }}</sb-button>
        </ng-template>
      </sb-dialog>
    </ng-container>
  `,
  standalone: true,
  imports: [TranslocoModule, DialogComponent, ChannelListComponent, ButtonComponent],
})
export class ConfirmIntegrationDeleteComponent implements OnInit {
  channels: Channel[] = []
  @Output() confirmDelete: EventEmitter<void> = new EventEmitter<void>()
  @Input() integration: Integration
  @Input() type: IntegrationType

  constructor(
    public dialogRef: MatDialogRef<ConfirmIntegrationDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) private data: InputData,
  ) {
    this.type = data.type
    this.integration = data.integration
  }

  ngOnInit(): void {
    this.channels = this.getChannels()
  }

  onConfirmDelete(): void {
    this.confirmDelete.emit()
  }

  private getChannels() {
    switch (this.type) {
      case 'facebook-account':
        const integration = this.integration as FacebookAccount
        return integration.instagram_accounts
          .concat(integration.facebook_pages)
          .concat(integration.facebook_ad_accounts)
      case 'linkedin-account':
        return (this.integration as LinkedinAccount).organizations
    }
  }
}
