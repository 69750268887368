import { ApplicationConfig } from '@angular/core'
import { provideTranslocoScope } from '@jsverse/transloco'

import { translocoScopeLoader } from '~core/utils'

export const commonConfig: ApplicationConfig['providers'] = []

export const commonI18nConfig: ApplicationConfig['providers'] = [
  // Provided via translationsConfig in app.config
  provideTranslocoScope({
    scope: 'common',
    loader: translocoScopeLoader((lang: string, root: string) => import(`./${root}/${lang}.json`)),
  }),
]
