import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators'

import { JsonResponse } from '~core/models/json-response.model'
import { UserService } from '~core/services'
import * as CoreActions from '~core/store/core.actions'
import { truthy } from '~core/utils/operators'
import { FacebookAccount } from '~features/integrations/models/facebook-account.model'
import { FacebookAccountService } from '~features/integrations/services/facebook-account.service'

import * as IntegrationActions from '../actions'

@Injectable()
export class FacebookAccountEffects {
  deleteFacebookAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.deleteFacebookAccount),
      exhaustMap(({ payload }) =>
        this.facebookAccountService.delete(payload._id).pipe(
          map(() => IntegrationActions.deleteFacebookAccountSuccess()),
          catchError((err: HttpErrorResponse) => of(IntegrationActions.deleteFacebookAccountError(err))),
        ),
      ),
    )
  })

  deleteFacebookAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IntegrationActions.deleteFacebookAccountSuccess),
        tap(() => this.userService.populate()),
      )
    },
    { dispatch: false },
  )

  loadFacebookAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.loadFacebookAccounts),
      switchMap(this.facebookAccountService.getAll),
      map((facebookAccounts) => IntegrationActions.loadFacebookAccountsSuccess(facebookAccounts)),
    )
  })

  loadFacebookAccountsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.loadFacebookAccountsSuccess),
      map(({ payload }: { payload: JsonResponse<FacebookAccount[]> }) => payload.data),
      map((facebookAccounts: FacebookAccount[]) => facebookAccounts.find((f: FacebookAccount) => f.is_primary)),
      truthy(),
      map(({ _id }: FacebookAccount) => IntegrationActions.setActiveFacebookAccount({ _id })),
    )
  })

  makePrimaryFacebookAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.makePrimaryFacebookAccount),
      exhaustMap(({ payload }) =>
        this.facebookAccountService.makePrimary(payload._id).pipe(
          map(() => IntegrationActions.loadFacebookAccounts()),
          catchError((err: HttpErrorResponse) => of(IntegrationActions.makePrimaryFacebookAccountError(err))),
        ),
      ),
    )
  })

  refreshFacebookAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.refreshFacebookAccount),
      exhaustMap(({ payload }) =>
        this.facebookAccountService.refreshToken(payload).pipe(
          map((response) => IntegrationActions.refreshFacebookAccountSuccess(response)),
          catchError((err: HttpErrorResponse) => of(IntegrationActions.refreshFacebookAccountError(err))),
        ),
      ),
    )
  })

  refreshFacebookAccountSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IntegrationActions.refreshFacebookAccountSuccess),
        tap(({ payload }) => this.userService.refreshFacebookAccountChannels(payload.data)),
      )
    },
    { dispatch: false },
  )
  storeFacebookAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.storeFacebookAccount),
      exhaustMap(({ payload }) =>
        this.facebookAccountService.store(payload).pipe(
          map((response) => IntegrationActions.storeFacebookAccountSuccess(response)),
          catchError((err: HttpErrorResponse) => of(IntegrationActions.storeFacebookAccountError(err))),
        ),
      ),
    )
  })
  storeFacebookAccountError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.storeFacebookAccountError),
      map(() => CoreActions.setLockedIsLoading({ payload: false })),
    )
  })
  storeFacebookAccountSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IntegrationActions.storeFacebookAccountSuccess),
      map(({ payload }) => IntegrationActions.setActiveFacebookAccount({ _id: payload.data._id })),
    )
  })

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private facebookAccountService: FacebookAccountService,
  ) {}
}
