import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { Subscription } from 'rxjs'

import { environment } from '~env'

import { NotFoundComponent } from '../not-found/not-found.component'

@Component({
  selector: 'sb-facebook-deletion',
  template: `<div class="main-content">
    @if (notFound === true) {
      <sb-not-found [showButton]="false"></sb-not-found>
    } @else {
      <div class="fb-deletion container">
        <div class="card mt-5">
          <div class="card-body">
            <ul class="list-group list-group-flush list my-n3">
              <li class="list-group-item px-0">
                <div class="row align-items-center justify-content-between mb-n3">
                  <div class="col-auto">
                    <h3>Facebook Data Deletion Status</h3>
                  </div>
                  <div class="col-auto">
                    <h3>
                      @if (deleted) {
                        <span class="badge badge-soft-success">Deleted</span>
                      } @else {
                        <h3>
                          <span class="badge badge-lg badge-soft-info">In progress</span>
                        </h3>
                      }
                    </h3>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    }
  </div> `,
  styles: [
    `
      .text-yellow {
        color: #f1c443;
      }

      .fb-deletion {
        max-width: 600px;
        background: #f9fbfd;
      }
    `,
  ],
  standalone: true,
  imports: [NotFoundComponent],
})
export class FacebookDeletionComponent implements OnInit {
  title: string
  deleted = false
  notFound = false
  private routeSubscription = Subscription.EMPTY

  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient,
  ) {}

  ngOnInit() {
    this.routeSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id')
      this.httpClient.get<{ deleted: boolean }>(`${environment.apiEndpoint}/facebook-deletion/${id}`).subscribe(
        (response) => (this.deleted = response.deleted),
        (err) => (this.notFound = true),
      )
    })
  }
}
