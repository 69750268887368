import { NgIf, NgFor } from '@angular/common'
import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core'
import { MatChipsModule } from '@angular/material/chips'
import { MatOptionModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { IconFill, Icon } from 'src/app/common/icons'
import { NavigationBarItem } from 'src/app/common/layout/navbar/navbar.component'
import { NavbarComponent } from 'src/app/common/layout/navbar/navbar.component'
import { ViewHeaderComponent } from 'src/app/common/layout/view-header/view-header.component'
import { ViewComponent } from 'src/app/common/layout/view/view.component'
import { AlertComponent } from 'src/app/common/ui/alert/alert.component'
import { BoxComponent } from 'src/app/common/ui/box/box.component'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { CardComponent } from 'src/app/common/ui/card/card.component'
import { DialogComponent } from 'src/app/common/ui/dialog/dialog.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { DialogService } from '~core/services/ui/dialog.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'

import { SearchBarComponent } from '../../../../common/components/filters/search-bar/search-bar.component'

@Component({
  selector: 'design-library',
  template: `<sb-view>
    <ng-template #toolbar>
      <sb-view-header
        title="Design library"
        subtitle="A collection of reusable components based on Sbam Design System by Pepuziniello"
      />
    </ng-template>
    <ng-template #content>
      <sb-card title="FABs">
        <ng-template #content>
          <div class="grid grid-cols-4">
            <div class="flex flex-col items-center gap-5">
              <sb-button variant="fab" [icon]="Icons.FileDownload" />
              <sb-button variant="miniFab" [icon]="Icons.FileDownload" />
            </div>
          </div>
        </ng-template>
      </sb-card>
      <div class="w-100 mt-8">
        <sb-card title="Alert">
          <ng-template #content>
            <div class="flex flex-col gap-5">
              <sb-alert variant="success" dismissible>Success alert. With a <a href="#">link</a></sb-alert>
              <sb-alert variant="warning" dismissible>Warning. With a <a href="#">link</a></sb-alert>
              <sb-alert variant="error" dismissible>Error. With a <a href="#">link</a></sb-alert>
              <sb-alert variant="secondary" dismissible>Secondary. With a <a href="#">link</a></sb-alert>
              <sb-alert variant="neutral" dismissible>Neutral. With a <a href="#">link</a></sb-alert>
            </div>
          </ng-template>
        </sb-card>
      </div>
      <div>
        <div class="mb-4">
          <sb-search-bar />
        </div>

        <sb-card title="Table" class="w-full">
          <ng-template #content>
            <table
              mat-table
              [dataSource]="[
                { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
                { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
                { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
                { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
                { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
                { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
                { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
                { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
                { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
                { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' }
              ]"
            >
              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>No.</th>
                <td mat-cell *matCellDef="let element">{{ element.position }}</td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef>Weight</th>
                <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="symbol">
                <th mat-header-cell *matHeaderCellDef>Symbol</th>
                <td mat-cell *matCellDef="let element">{{ element.symbol }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['position', 'name', 'weight', 'symbol']"></tr>
              <tr mat-row *matRowDef="let row; columns: ['position', 'name', 'weight', 'symbol']"></tr>
            </table>
          </ng-template>
        </sb-card>

        <div class="mb-2 grid grid-cols-3 gap-2">
          <sb-card title="List">
            <ng-template #content>
              <mat-list>
                <mat-list-item>List item</mat-list-item>
                <mat-list-item>List item</mat-list-item>
                <mat-list-item>List item</mat-list-item>
                <mat-list-item>List item</mat-list-item>
                <mat-list-item>List item</mat-list-item>
              </mat-list>
            </ng-template>
          </sb-card>

          <sb-card title="Selection List">
            <ng-template #content>
              <mat-selection-list>
                <mat-list-item>List item</mat-list-item>
                <mat-list-item>List item</mat-list-item>
                <mat-list-item>List item</mat-list-item>
                <mat-list-item>List item</mat-list-item>
                <mat-list-item>List item</mat-list-item>
              </mat-selection-list>
            </ng-template>
          </sb-card>

          <sb-card title="Options Selection List">
            <ng-template #content>
              <mat-selection-list color="primary">
                <mat-list-option>List option</mat-list-option>
                <mat-list-option>List option</mat-list-option>
                <mat-list-option>List option</mat-list-option>
                <mat-list-option>List option</mat-list-option>
                <mat-list-option>List option</mat-list-option>
              </mat-selection-list>
            </ng-template>
          </sb-card>
        </div>
        <sb-card title="Navigation bar">
          <ng-template #content>
            <sb-navbar *ngIf="nabvarItems.length > 1" [items]="nabvarItems"></sb-navbar>
          </ng-template>
        </sb-card>

        <sb-box direction="row" gap="1rem">
          <sb-card title="Chips">
            <ng-template #content>
              <mat-chip-listbox>
                <mat-chip-option>Not selected</mat-chip-option>
                <mat-chip-option selected>Selected</mat-chip-option>
              </mat-chip-listbox>
            </ng-template>
          </sb-card>

          <sb-card title="Dialog and Snackbar">
            <ng-template #content>
              <sb-box direction="row" gap="1rem">
                <sb-button variant="filled" (sbClick)="openDialog(dialog)">Open sample dialog</sb-button>
                <sb-button variant="filled" (sbClick)="openSnackbar()">Show snackbar</sb-button>
              </sb-box>
            </ng-template>
          </sb-card>
        </sb-box>

        <!-- Dialog template -->
        <ng-template #dialog>
          <sb-dialog title="Basic dialog title">
            <ng-template #content>
              A dialog is a variant of modal window that appears in front of app content to provide critical
              information, or prompt for a decision to be made.
            </ng-template>
            <ng-template #actions>
              <sb-button variant="text">Action 2</sb-button>
              <sb-button variant="text">Action 1</sb-button>
            </ng-template>
          </sb-dialog>
        </ng-template>

        <sb-box direction="row" gap="1rem">
          <sb-box maxWidth="300px">
            <sb-card title="Header" subtitle="Subhead" imageSrc="/assets/img/media-placeholder.png">
              <ng-template #content>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              </ng-template>
              <ng-template #actions>
                <sb-button variant="outlined">Enabled</sb-button>
                <sb-button variant="filled">Enabled</sb-button>
              </ng-template>
            </sb-card>
          </sb-box>
          <sb-box maxWidth="300px">
            <sb-card elevated title="Header" subtitle="Subhead" imageSrc="/assets/img/media-placeholder.png">
              <ng-template #content>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              </ng-template>
              <ng-template #actions>
                <sb-button variant="outlined">Enabled</sb-button>
                <sb-button variant="filled">Enabled</sb-button>
              </ng-template>
            </sb-card>
          </sb-box>
        </sb-box>

        <sb-box>
          <sb-card title="Datepickers">
            <ng-template #content>
              <sb-box direction="row">
                <div style="width: 300px">
                  <p>Inline calendar</p>
                  <mat-calendar></mat-calendar>
                </div>
                <div>
                  <p>Picker</p>
                  <mat-form-field appearance="fill">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div>
                  <p>Range picker</p>
                  <mat-form-field appearance="fill">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="rangePicker">
                      <input matStartDate placeholder="Start date" />
                      <input matEndDate placeholder="End date" />
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker>
                      <mat-datepicker-actions>
                        <sb-button variant="text">Cancel</sb-button>
                        <sb-button variant="text">Ok</sb-button>
                      </mat-datepicker-actions>
                    </mat-date-range-picker>
                  </mat-form-field>
                </div>
              </sb-box>
            </ng-template>
          </sb-card>
        </sb-box>

        <sb-card title="Form fields">
          <ng-template #content>
            <sb-box direction="row" gap="1rem">
              <sb-box>
                <h4>Text</h4>
                <mat-form-field appearance="fill">
                  <mat-label>Fill form field</mat-label>
                  <input matInput placeholder="Placeholder" />
                  <mat-hint>Hint</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Disabled fill form field</mat-label>
                  <input matInput placeholder="Placeholder" disabled />
                  <mat-hint>Hint</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Outline form field</mat-label>
                  <input matInput placeholder="Placeholder" />
                  <mat-hint>Hint</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Disabled outline form field</mat-label>
                  <input matInput placeholder="Placeholder" disabled />
                  <mat-hint>Hint</mat-hint>
                </mat-form-field>
              </sb-box>
              <sb-box>
                <h4>Select</h4>
                <mat-form-field appearance="fill">
                  <mat-select required>
                    <mat-option>-- None --</mat-option>
                    <mat-option value="option">Option</mat-option>
                  </mat-select>
                  <mat-label>Label</mat-label>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-select required>
                    <mat-option>-- None --</mat-option>
                    <mat-option value="option">Option</mat-option>
                  </mat-select>
                  <mat-label>Label</mat-label>
                </mat-form-field>
              </sb-box>

              <sb-box
                ><h4>Textarea</h4>
                <mat-form-field appearance="fill">
                  <mat-label>Textarea</mat-label>
                  <textarea matInput></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Textarea</mat-label>
                  <textarea matInput></textarea>
                </mat-form-field>
              </sb-box>

              <sb-box
                ><h4>Radio</h4>
                <mat-radio-group color="primary">
                  <mat-radio-button value="auto">Auto</mat-radio-button>
                  <mat-radio-button value="always">Always</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group>
                  <mat-radio-button value="auto">Auto</mat-radio-button>
                  <mat-radio-button value="always">Always</mat-radio-button>
                </mat-radio-group>
              </sb-box>
            </sb-box>
          </ng-template>
        </sb-card>

        <sb-box direction="row" gap="1rem">
          <sb-card title="Typography">
            <ng-template #content>
              <div class="flex flex-col gap-5">
                <sb-text noMargin variant="headlineLarge">Headline large</sb-text>
                <sb-text noMargin variant="headlineMedium">Headline medium</sb-text>
                <sb-text noMargin variant="headlineSmall">Headline small</sb-text>
                <sb-text noMargin variant="title">Title</sb-text>
                <sb-text noMargin variant="subtitle">Subtitle</sb-text>
                <sb-text noMargin variant="titleLarge">Title large</sb-text>
                <sb-text noMargin variant="body">Body</sb-text>
                <sb-text noMargin variant="bodyMedium">Bold body</sb-text>
                <sb-text noMargin variant="label">Labek</sb-text>
                <sb-text noMargin variant="labelLarge">Labek</sb-text>
              </div>
            </ng-template>
          </sb-card>

          <sb-card title="Buttons" elevated>
            <ng-template #content>
              <p>Filled</p>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="filled">Enabled</sb-button>
                <sb-button variant="filled" disabled>Disabled</sb-button>
              </sb-box>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="filled" [icon]="Icons.Add">Enabled</sb-button>
                <sb-button variant="filled" disabled [icon]="Icons.Add">Disabled</sb-button>
              </sb-box>
              <p>Outlined</p>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="outlined">Enabled</sb-button>
                <sb-button variant="outlined" disabled>Disabled</sb-button>
              </sb-box>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="outlined" [icon]="Icons.Add">Enabled</sb-button>
                <sb-button variant="outlined" disabled [icon]="Icons.Add">Disabled</sb-button>
              </sb-box>
              <p>Text</p>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="text">Enabled</sb-button>
                <sb-button variant="text" disabled>Disabled</sb-button>
              </sb-box>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="text" [icon]="Icons.Add">Enabled</sb-button>
                <sb-button variant="text" disabled [icon]="Icons.Add">Disabled</sb-button>
              </sb-box>
              <p>Elevated</p>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="elevated">Enabled</sb-button>
                <sb-button variant="elevated" disabled>Disabled</sb-button>
              </sb-box>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="elevated" [icon]="Icons.Add">Enabled</sb-button>
                <sb-button variant="elevated" disabled [icon]="Icons.Add">Disabled</sb-button>
              </sb-box>
              <p>Tonal</p>
              <sb-box direction="row" gap="0.5rem" width="100%" margin="0 0 1rem">
                <sb-button variant="tonal" [icon]="Icons.Add">Enabled</sb-button>
                <sb-button variant="tonal" disabled [icon]="Icons.Add">Disabled</sb-button>
              </sb-box>
            </ng-template>
          </sb-card>
          <div class="w-1/4">
            <sb-card title="Tabs">
              <ng-template #content>
                <sb-text>Fixed primary</sb-text>
                <mat-tab-group fitInkBarToContent>
                  <mat-tab label="First">Content 1</mat-tab>
                  <mat-tab label="Second">Content 2</mat-tab>
                  <mat-tab label="Third">Content 3</mat-tab>
                </mat-tab-group>

                <sb-text>Fixed secondary</sb-text>
                <mat-tab-group>
                  <mat-tab label="First">Content 1</mat-tab>
                  <mat-tab label="Second">Content 2</mat-tab>
                  <mat-tab label="Third">Content 3</mat-tab>
                </mat-tab-group>
                <sb-text>Paginated</sb-text>
                <mat-tab-group>
                  <mat-tab [label]="tab" *ngFor="let tab of lotsOfTabs">Content</mat-tab>
                </mat-tab-group>
              </ng-template>
            </sb-card>
          </div>
        </sb-box>
      </div>
    </ng-template>
  </sb-view> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ViewComponent,
    ViewHeaderComponent,
    CardComponent,
    ButtonComponent,
    AlertComponent,
    MatTableModule,
    MatListModule,
    NgIf,
    NavbarComponent,
    BoxComponent,
    MatChipsModule,
    DialogComponent,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatRadioModule,
    TextComponent,
    MatTabsModule,
    NgFor,
    SearchBarComponent,
  ],
})
export class DesignLibraryComponent {
  dialogRef: any
  infobox = {
    title: 'Title',
    description: 'Description',
    type: 'Type',
    hint: 'Hint',
  }
  lotsOfTabs = new Array(30).fill(0).map((_, index) => `Tab ${index}`)
  nabvarItems: NavigationBarItem[] = [
    { label: 'Default', path: null },
    {
      label: 'Active',
      path: '/admin/design-library',
    },
    { label: 'Home', path: '/' },
    { label: 'User Account', path: '/user' },
  ]
  protected Icons = Icon
  protected IconsFill = IconFill

  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
  ) {}

  openDialog(dialog: TemplateRef<HTMLElement>) {
    this.dialogService.open(dialog, { maxWidth: 600 })
  }

  openSnackbar() {
    this.snackbarService.open('Snackbar message', 'Action')
  }
}
