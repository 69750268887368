import { Component, Inject, inject, output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'

import { ButtonComponent } from '~common/ui/button/button.component'
import { DialogComponent } from '~common/ui/dialog/dialog.component'
import { CommentItemComponent } from '~features/comments/components/comment-item/comment-item.component'
import { Comment, SentimentType } from '~features/comments/types'

@Component({
  selector: 'sb-edit-comment-dialog',
  standalone: true,
  template: `
    <sb-dialog title="Edit comment">
      <ng-template #content>
        <sb-comment-item [comment]="data.comment" />
        <mat-form-field class="mt-4">
          <mat-label>Proposed sentiment</mat-label>
          <mat-select [(value)]="proposedSentiment">
            @for (sentiment of sentiments; track sentiment) {
              <mat-option [value]="sentiment">{{ sentiment }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </ng-template>
      <ng-template #actions>
        <sb-button (click)="dialogRef.close()">Cancel</sb-button>
        <sb-button (click)="onSave.emit({ comment: data.comment, proposedSentiment })">Save</sb-button>
      </ng-template>
    </sb-dialog>
  `,
  styles: ``,
  imports: [MatFormFieldModule, MatSelectModule, DialogComponent, ButtonComponent, CommentItemComponent],
})
export class EditCommentDialogComponent {
  dialogRef = inject(MatDialogRef)
  proposedSentiment = this.data.comment.proposedSentiment
  sentiments: SentimentType[] = ['POSITIVE', 'MIXED', 'NEGATIVE', 'NEUTRAL', 'UNCLASSIFIED']
  onSave = output<{ comment: Comment; proposedSentiment: SentimentType }>()

  constructor(@Inject(MAT_DIALOG_DATA) public data: { comment: Comment }) {}
}
